<template>
  <div class="content">
    <div class="warning">FBI WARNING</div>
    <div class="title">本群含有以下元素/人物:</div>
    <div class="list">
      <div class="item">
        <img src="../assets/images/1.png" alt="" />
        <div>少儿不宜</div>
      </div>
      <div class="item">
        <img src="../assets/images/2.png" alt="" />
        <div>复读机</div>
      </div>
      <div class="item">
        <img src="../assets/images/3.png" alt="" />
        <div>女装大佬</div>
      </div>
      <div class="item">
        <img src="../assets/images/4.png" alt="" />
        <div>gay</div>
      </div>
    </div>
    <div class="list">
      <div class="item">
        <img src="../assets/images/5.jpg" alt="" />
        <div>咸鱼</div>
      </div>
      <div class="item">
        <img src="../assets/images/6.png" alt="" />
        <div>群宠</div>
      </div>
      <div class="item">
        <img src="../assets/images/7.png" alt="" />
        <div>枪毙名单</div>
      </div>
      <div class="item">
        <img src="../assets/images/8.png" alt="" />
        <div>秃子</div>
      </div>
    </div>
    <div class="list">
      <div class="item">
        <img src="../assets/images/9.jpg" alt="" />
        <div>小花</div>
      </div>
      <div class="item">
        <img src="../assets/images/10.jpg" alt="" />
        <div>二刺螈</div>
      </div>
    </div>
    <div class="title" style="margin-top: 20px">如无法适应,请尽快退群</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      id: "",
    };
  },
  components: {},

  created() {},
  methods: {},
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: auto;
  .warning {
    width: 90%;
    margin: 3% 5% 3% 5%;
    height: 50px;
    background: red;
    color: white;
    font-weight: 500;
    text-align: center;
    font-size: 28px;
    line-height: 50px;
  }
  .title {
    width: 90%;
    margin-left: 5%;
    font-size: 16px;
    color: black;
    font-weight: 700;
  }
  .list {
    width: 100%;
    height: auto;
    display: flex;
    margin-top: 10px;
    .item {
      text-align: center;
      width: 25%;
      height: 110px;
      img {
        width: 70px;
        height: 70px;
        object-fit: cover;
      }
      div {
        width: 100%;
        height: 20px;
      }
    }
  }
}
</style>
